import React, {useEffect} from "react";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div">
        <div className="overlap">
          <div className="hero">
            <div className="group">
              <div className="frame">
                <div className="UIX">
                  <div className="frame-wrapper">
                    <div className="frame-2">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                          <img className="line" alt="Line" src="/img/line-18.svg" />
                          <img className="img" alt="Line" src="/img/line-19-1.svg" />
                        </div>
                      </div>
                      <div className="text-wrapper">App Design</div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper">
                  <div className="frame-wrapper">
                    <div className="frame-2">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                          <img className="line" alt="Line" src="/img/line-18.svg" />
                          <img className="img" alt="Line" src="/img/line-19-1.svg" />
                        </div>
                      </div>
                      <div className="text-wrapper">UI/UX</div>
                    </div>
                  </div>
                </div>
                <div className="UIX-2">
                  <div className="frame-wrapper">
                    <div className="frame-2">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                          <img className="line" alt="Line" src="/img/line-18.svg" />
                          <img className="img" alt="Line" src="/img/line-19.svg" />
                        </div>
                      </div>
                      <div className="text-wrapper">Branding</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-2">Scope of Work</div>
            </div>
          </div>
          <div className="frame-3">
            <div className="android-IOS">Android &amp; IOS</div>
            <div className="text-wrapper-3">Lifestyle</div>
          </div>
          <div className="frame-4">
            <div className="text-wrapper-4">Peperstreet</div>
            <p className="p">Finding Love - from Reels to Real!</p>
          </div>
        </div>
        <div className="the-challenge">
          <div className="frame-5">
            <div className="frame-6">
              <div className="frame-7">
                <div className="text-wrapper-5">The Challenge</div>
              </div>
              <p className="finding-love-in-a">
                <span className="span">
                  Finding love in a Swipe Universe now is the new norm. While, everyone is on dating apps and meeting
                  your Ms. or Mr. Special might sound easy. But it is’nt!
                </span>
                <span className="text-wrapper-6">
                  {"  "}That too after trying it for the nth time -number of trials and errors. After matching up with
                  someone on a dating app, getting to know them in person may or may not meet your expectations. While
                  the profile happens to be the key play - And that comes with factors - Like points along with a series
                  of expectations, how to’s and so much more.
                  <br />
                  We guess finding love is not that easy. But we are just getting started, Finding love maybe difficult
                  but who says it has to be boring either?{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="logo-wrapper">
            <img className="logo" alt="Logo" src="/img/logo.png" />
          </div>
        </div>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="frame-8">
              <div className="frame-9">
                <div className="text-wrapper-7">Introducing</div>
                <p className="text-wrapper-8">
                  Peperstreet - Video Dating App, is a new dating app that helps people avoid boring monotous swipes
                  with Video First Profiles. The app is based on a strict rules – Build connections, your profile can’t
                  be boring whatsover and most of all keep it fun
                </p>
              </div>
              <p className="text-wrapper-9">
                The founder reached out to us, with the idea, and before getting excited, we empathized with the problem
                ourselves. The whole idea of the application was fusing the world of tinder and tiktok in one was it
                easy? Nah. Like every other project - We put on our hats to first validate and get the sentiment of
                people using dating apps.
              </p>
            </div>
            <div className="frame-8">
              <div className="frame-9">
                <div className="text-wrapper-7">Introducing</div>
                <p className="text-wrapper-8">
                  Peperstreet - Video Dating App, is a new dating app that helps people avoid boring monotous swipes
                  with Video First Profiles. The app is based on a strict rules – Build connections, your profile can’t
                  be boring whatsover and most of all keep it fun
                </p>
              </div>
              <p className="text-wrapper-9">
                The founder reached out to us, with the idea, and before getting excited, we empathized with the problem
                ourselves. The whole idea of the application was fusing the world of tinder and tiktok in one was it
                easy? Nah. Like every other project - We put on our hats to first validate and get the sentiment of
                people using dating apps.
              </p>
            </div>
          </div>
          <div className="overlap-4">
            <div className="rectangle" />
            <div className="rectangle" />
            <img className="iphone" alt="Iphone" src="/img/iphone-16.png" />
            <img className="iphone" alt="Iphone" src="/img/iphone-16.png" />
            <div className="group-2">
              <div className="overlap-5">
                <div className="rectangle-2" />
                <p className="competitive">Competitive Analaysis &amp; Market Research</p>
                <div className="research">
                  <img className="screenshot" alt="Screenshot" src="/img/screenshot-2022-08-05-at-3-39-1.png" />
                </div>
                <div className="research-2">
                  <img className="screenshot-2" alt="Screenshot" src="/img/screenshot-2022-08-05-at-3-56-1.png" />
                  <p className="text-wrapper-10">The Industry in a nutshell</p>
                </div>
                <div className="screenshot-wrapper">
                  <img className="screenshot-3" alt="Screenshot" src="/img/screenshot-2022-08-05-at-5-55-1.png" />
                </div>
                <p className="text-wrapper-11">
                  While, every product has a strategic play afterall. To align better with the industry nitty gritties
                  and understand the space better to figure out the positioning factors - both from a branding as well
                  as product perspective.
                </p>
                <p className="text-wrapper-12">Taking a Holistic Look at the market</p>
                <div className="frame-10">
                  <div className="frame-11">
                    <div className="text-wrapper-13">Asking the right questions</div>
                    <div className="text-wrapper-14">User Research</div>
                  </div>
                  <p className="text-wrapper-15">
                    With a mix of qualitative and quantitative analysis we tried undesratnding the overall sentiment and
                    figured that most people use dating apps - for fun, and by further diverging into it, we figured
                    more patterns to make it a engaging and fun experience.
                  </p>
                </div>
                <div className="user-goals">
                  <div className="group-3">
                    <div className="text-wrapper-16">Personal Goals</div>
                    <p className="text-wrapper-17">Online dates say they’re looking for</p>
                    <div className="group-4">
                      <div className="text-wrapper-18">For ‘fun’</div>
                      <div className="group-5">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-19">52%</div>
                        </div>
                      </div>
                    </div>
                    <div className="group-6">
                      <div className="text-wrapper-18">for sex</div>
                      <div className="overlap-6">
                        <div className="text-wrapper-20">13%</div>
                      </div>
                    </div>
                    <div className="group-7">
                      <div className="text-wrapper-18">For ‘friends”</div>
                      <div className="overlap-7">
                        <div className="text-wrapper-21">37%</div>
                      </div>
                    </div>
                    <div className="group-8">
                      <p className="text-wrapper-22">to find a partner ‘friends”</p>
                      <div className="overlap-8">
                        <div className="text-wrapper-23">21%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ecosystem">
                  <div className="text-wrapper-24">The Dating App Ecosystem</div>
                  <p className="text-wrapper-25">
                    Primarily the entire app ecosystem falls under two categories Casual dating and Matchmaking. However
                    each app solves a different problem altogether in the dating world. This matrix gives the idea on
                    how the ecosystem looks like from a top level view. The matrix looks at fundamentally the user goals
                    from top to bottom, and what’s the problem each player is solving from right to left and top to
                    bottom.
                  </p>
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="overlap-5">
                <div className="rectangle-2" />
                <p className="competitive">Competitive Analaysis &amp; Market Research</p>
                <div className="research">
                  <img className="screenshot" alt="Screenshot" src="/img/screenshot-2022-08-05-at-3-39-1.png" />
                </div>
                <div className="research-3">
                  <img className="screenshot-2" alt="Screenshot" src="/img/screenshot-2022-08-05-at-3-56-1.png" />
                  <p className="text-wrapper-10">The Industry in a nutshell</p>
                </div>
                <div className="screenshot-wrapper">
                  <img className="screenshot-3" alt="Screenshot" src="/img/screenshot-2022-08-05-at-5-55-1.png" />
                </div>
                <p className="text-wrapper-26">
                  While, every product has a strategic play afterall. To align better with the industry nitty gritties
                  and understand the space better to figure out the positioning factors - both from a branding as well
                  as product perspective.
                </p>
                <p className="text-wrapper-12">Taking a Holistic Look at the market</p>
                <div className="frame-10">
                  <div className="frame-11">
                    <div className="text-wrapper-13">Asking the right questions</div>
                    <div className="text-wrapper-14">User Research</div>
                  </div>
                  <p className="text-wrapper-15">
                    With a mix of qualitative and quantitative analysis we tried undesratnding the overall sentiment and
                    figured that most people use dating apps - for fun, and by further diverging into it, we figured
                    more patterns to make it a engaging and fun experience.
                  </p>
                </div>
                <div className="user-goals">
                  <div className="group-3">
                    <div className="text-wrapper-16">Personal Goals</div>
                    <p className="text-wrapper-17">Online dates say they’re looking for</p>
                    <div className="group-4">
                      <div className="text-wrapper-18">For ‘fun’</div>
                      <div className="group-5">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-19">52%</div>
                        </div>
                      </div>
                    </div>
                    <div className="group-6">
                      <div className="text-wrapper-18">for sex</div>
                      <div className="overlap-6">
                        <div className="text-wrapper-20">13%</div>
                      </div>
                    </div>
                    <div className="group-7">
                      <div className="text-wrapper-18">For ‘friends”</div>
                      <div className="overlap-7">
                        <div className="text-wrapper-21">37%</div>
                      </div>
                    </div>
                    <div className="group-8">
                      <p className="text-wrapper-22">to find a partner ‘friends”</p>
                      <div className="overlap-8">
                        <div className="text-wrapper-23">21%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ecosystem-2">
                  <div className="text-wrapper-27">The Dating App Ecosystem</div>
                  <div className="text-wrapper-28">Product Positioning</div>
                  <p className="text-wrapper-29">
                    Primarily the entire app ecosystem falls under two categories Casual dating and Matchmaking. However
                    each app solves a different problem altogether in the dating world. This matrix gives the idea on
                    how the ecosystem looks like from a top level view. The matrix looks at fundamentally the user goals
                    from top to bottom, and what’s the problem each player is solving from right to left and top to
                    bottom.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-wrapper-30">
          First on the list, was to establish a brand identity system and kit that aligns well with the app intent,
          vision and business objectives
        </p>
        <div className="text-wrapper-31">Brand Identity</div>
        <div className="text-wrapper-32">The essentials</div>
        <img className="frame-12" alt="Frame" src="/img/frame-42539.png" />
        <div className="rectangle-wrapper">
          <div className="rectangle-3" />
        </div>
        <img className="image" src="/img/Frame-42567.png"/>
        <div className="frame-13">
          <div className="text-wrapper-33">The Result</div>
          <p className="text-wrapper-34">
            The result: An evovled, connection-driven brand that balances a high-performance product experience with a
            more engaging and inspiring creative expression. A peperstreet that brings people together, and sometimes a
            little bit more - where they finally find they’ve been looking for.
          </p>
        </div>
        <div className="mockup-wrapper">
          <div className="mockup">
            <div className="overlap-9">
              <div className="body-space-gray">
                <div className="overlap-10">
                  <div className="button-space-gray">
                    <div className="overlap-group-3">
                      <div className="rectangle-4" />
                      <img className="union" alt="Union" src="/img/union-3.svg" />
                    </div>
                  </div>
                  <div className="button-space-gray-2">
                    <div className="overlap-11">
                      <div className="rectangle-5" />
                      <img className="union-2" alt="Union" src="/img/union-1.svg" />
                    </div>
                  </div>
                  <div className="button-space-gray-3">
                    <div className="overlap-11">
                      <div className="rectangle-5" />
                      <img className="union-2" alt="Union" src="/img/union.svg" />
                    </div>
                  </div>
                  <img className="img-2" alt="Frame" src="/img/frame.svg" />
                  <img className="img-2" alt="Shine" src="/img/shine.png" />
                  <img className="outer-ring" alt="Outer ring" src="/img/outer-ring.svg" />
                  <img className="bands" alt="Bands" src="/img/bands.svg" />
                </div>
                <div className="button-space-gray-4">
                  <div className="rectangle-6" />
                  <img className="union-3" alt="Union" src="/img/union-2.png" />
                </div>
              </div>
              <img className="base" alt="Base" src="/img/base.svg" />
              <div className="display">
                <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
              </div>
            </div>
          </div>
        </div>
        <img className="frame-14" alt="Frame" src="/img/frame-42565.svg" />
        <img
          className="holding-hand"
          alt="Holding hand"
          src="/img/holding-hand-landscape-business-card-free-mockup-1.png"
        />
        <img className="img-3" alt="Element" src="/img/15.png" />
      </div>
    </div>
  );
};
